import React from "react"

const DocumentationIcon = () => {
    return (
        <svg
            width="118"
            height="118"
            viewBox="0 0 118 118"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M16.3672 31.2626V16.3685L31.2624 16.3685M31.2624 101.633H16.3674L16.3674 86.738M86.7377 16.3676L101.631 16.3674V31.2625M101.631 86.7378V101.632H86.7377"
                stroke="white"
                strokeWidth="0.5"
            />
            <g filter="url(#filter0_d_775_2)">
                <circle cx="58.9999" cy="59.0002" r="30.7143" fill="#0E9DE5" />
            </g>
            <g filter="url(#filter1_d_775_2)">
                <circle cx="59" cy="59" r="25" fill="#0C0C1C" />
            </g>
            <g filter="url(#filter2_d_775_2)">
                <circle
                    className="group-hover:animate-spin"
                    style={{
                        transformOrigin: "center",
                        animationDuration: "10000ms",
                    }}
                    cx="59"
                    cy="59"
                    r="42.5"
                    stroke="white"
                    strokeDasharray="5 5"
                    shapeRendering="crispEdges"
                />
            </g>
            <g clipPath="url(#clip0_775_2)">
                <path
                    d="M60.3115 48.0444H48V71.9999H67.8008V55.0066H60.3115V48.0444ZM57.9004 65.3241H51.0428V64.0401H57.9004V65.3241ZM64.4543 62.4992H51.043V61.2152H64.4544L64.4543 62.4992ZM64.4543 58.3903V59.6743H51.043V58.3903H64.4543Z"
                    fill="white"
                />
                <path
                    d="M49.5713 46V47.1681H60.8368C62.466 48.7103 67.0605 52.8847 68.7434 54.4116V70.5393H70V46H49.5713Z"
                    fill="white"
                />
            </g>
            <defs>
                <filter
                    id="filter0_d_775_2"
                    x="12.2856"
                    y="12.2859"
                    width="93.4287"
                    height="93.4285"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset />
                    <feGaussianBlur stdDeviation="8" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0.626318 0 0 0 0 0.941667 0 0 0 1 0"
                    />
                    <feBlend
                        mode="normal"
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow_775_2"
                    />
                    <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="effect1_dropShadow_775_2"
                        result="shape"
                    />
                </filter>
                <filter
                    id="filter1_d_775_2"
                    x="18"
                    y="18"
                    width="82"
                    height="82"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset />
                    <feGaussianBlur stdDeviation="8" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0.626318 0 0 0 0 0.941667 0 0 0 1 0"
                    />
                    <feBlend
                        mode="normal"
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow_775_2"
                    />
                    <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="effect1_dropShadow_775_2"
                        result="shape"
                    />
                </filter>
                <filter
                    id="filter2_d_775_2"
                    x="0"
                    y="0"
                    width="118"
                    height="118"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset />
                    <feGaussianBlur stdDeviation="8" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0.626318 0 0 0 0 0.941667 0 0 0 1 0"
                    />
                    <feBlend
                        mode="normal"
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow_775_2"
                    />
                    <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="effect1_dropShadow_775_2"
                        result="shape"
                    />
                </filter>
                <clipPath id="clip0_775_2">
                    <rect
                        width="22"
                        height="26"
                        fill="white"
                        transform="translate(48 46)"
                    />
                </clipPath>
            </defs>
        </svg>
    )
}

export default DocumentationIcon
