import React from "react"

type CardHeader = {
    subheading: string
    title: string
    subtitle: string
}

const CardHeader: React.FC<CardHeader> = ({ subheading, title, subtitle }) => {
    return (
        <div className="text-center md:pb-6">
            <span
                className={`block text-horizen-grey text-[0.6rem] md:text-sm tracking-[0.2em] font-bold mb-4 min-h-[25px] lg:min-h-0 ${
                    subheading === "FOR HIGH SPEED"
                        ? "max-w-[5rem] mx-auto md:max-w-none"
                        : ""
                }`}
            >
                {subheading}
            </span>
            <span className="block font-bold text-white text-[1.375rem] lg:text-[2.5625rem] mb-2">
                {title}
            </span>
            <span className="hidden md:block text-sm lg:text-base lg:max-w-[15rem] lg:mx-auto">
                {subtitle}
            </span>
        </div>
    )
}

export default CardHeader
