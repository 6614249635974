import React from "react"
import { useIntl } from "gatsby-plugin-intl"
import ContentContainer from "../templates/ContentContainer"
import DiscordIcon from "./LearnZendoo/DiscordIcon"
import DocumentationIcon from "./LearnZendoo/DocumentationIcon"
import GithubIcon from "./LearnZendoo/GithubIcon"
import IconWrapper from "./LearnZendoo/IconWrapper"
import SectionHeading from "./SectionHeading"
import TutorialIcon from "./LearnZendoo/TutorialIcon"

const LearnZendoo = () => {
    const { formatMessage } = useIntl()

    return (
        <div className="text-white">
            <ContentContainer>
                <SectionHeading>
                    {formatMessage({
                        id: "zendoopage.learn.sectionHeading",
                    })}
                </SectionHeading>
                <div className="grid grid-cols-1 lg:grid-cols-3 gap-1 md:gap-4 mt-10">
                    <IconWrapper
                        label={formatMessage({
                            id: "zendoopage.learn.documentation",
                        })}
                        icon={<DocumentationIcon />}
                        href={formatMessage({
                            id: "zendoopage.learn.documentation.link",
                        })}
                        openNewTab
                    />
                    <IconWrapper
                        label={formatMessage({
                            id: "zendoopage.learn.github",
                        })}
                        icon={<GithubIcon />}
                        href={formatMessage({
                            id: "zendoopage.learn.github.link",
                        })}
                        openNewTab
                    />
                    <IconWrapper
                        label={formatMessage({
                            id: "zendoopage.learn.developerChats",
                        })}
                        icon={<DiscordIcon />}
                        href={formatMessage({
                            id: "zendoopage.learn.developerChats.link",
                        })}
                        openNewTab
                    />
                </div>
            </ContentContainer>
        </div>
    )
}

export default LearnZendoo
