import React, { useState } from "react"

import { useIntl } from "gatsby-plugin-intl"
import ContentContainer from "../templates/ContentContainer"
import ExpandableItem from "./ExpandableSection/ExpandableItem"

const ExpandableSection = () => {
    const [selectedExpandableItem, setSelectedExpandableItem] = useState(0)
    const { formatMessage } = useIntl()

    const handleCollapse = (index: number) => {
        if (window.innerWidth < 768) {
            if (selectedExpandableItem === index) {
                setSelectedExpandableItem(-1)
            } else {
                setSelectedExpandableItem(index)
            }
        } else {
            setSelectedExpandableItem(index)
        }
    }
    return (
        <div className="bg-horizen-hp-bg text-white">
            <ContentContainer>
                <div className="border-t border-b md:grid md:grid-cols-2 relative">
                    <div>
                        <ExpandableItem
                            label={formatMessage({
                                id: "zendoopage.expandableSection.data.1.title",
                            })}
                            handler={() => handleCollapse(0)}
                            active={selectedExpandableItem === 0}
                        >
                            <div>
                                {formatMessage({
                                    id: "zendoopage.expandableSection.data.1.p1",
                                })}
                                <ul className="list-disc pl-8 space-y-4">
                                    <li className="mt-4">
                                        {formatMessage({
                                            id: "zendoopage.expandableSection.data.1.l1",
                                        })}
                                    </li>
                                    <li>
                                        {formatMessage({
                                            id: "zendoopage.expandableSection.data.1.l2",
                                        })}
                                    </li>
                                    <li>
                                        {formatMessage({
                                            id: "zendoopage.expandableSection.data.1.l3",
                                        })}
                                    </li>
                                </ul>
                            </div>
                        </ExpandableItem>

                        <ExpandableItem
                            label={formatMessage({
                                id: "zendoopage.expandableSection.data.2.title",
                            })}
                            handler={() => handleCollapse(1)}
                            active={selectedExpandableItem === 1}
                        >
                            <div className="space-y-8">
                                <p>
                                    {formatMessage({
                                        id: "zendoopage.expandableSection.data.2.p1",
                                    })}
                                </p>
                                <p>
                                    {formatMessage({
                                        id: "zendoopage.expandableSection.data.2.p2",
                                    })}
                                </p>
                                <p>
                                    {formatMessage({
                                        id: "zendoopage.expandableSection.data.2.p3",
                                    })}
                                </p>
                                <p>
                                    {formatMessage({
                                        id: "zendoopage.expandableSection.data.2.p4",
                                    })}
                                </p>
                            </div>
                        </ExpandableItem>
                        <ExpandableItem
                            label={formatMessage({
                                id: "zendoopage.expandableSection.data.3.title",
                            })}
                            handler={() => handleCollapse(2)}
                            active={selectedExpandableItem === 2}
                        >
                            <div className="space-y-8">
                                <p>
                                    {formatMessage({
                                        id: "zendoopage.expandableSection.data.3.p1",
                                    })}
                                </p>
                            </div>
                        </ExpandableItem>
                        <ExpandableItem
                            label={formatMessage({
                                id: "zendoopage.expandableSection.data.4.title",
                            })}
                            handler={() => handleCollapse(3)}
                            active={selectedExpandableItem === 3}
                        >
                            <div className="space-y-8">
                                <p>
                                    {formatMessage({
                                        id: "zendoopage.expandableSection.data.4.p1",
                                    })}
                                </p>
                                <p>
                                    {formatMessage({
                                        id: "zendoopage.expandableSection.data.4.p2",
                                    })}
                                </p>
                                <p>
                                    {formatMessage({
                                        id: "zendoopage.expandableSection.data.4.p3",
                                    })}
                                </p>
                            </div>
                        </ExpandableItem>
                    </div>
                </div>
            </ContentContainer>
        </div>
    )
}

export default ExpandableSection
