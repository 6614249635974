import React from "react"
import ExpandableButton from "../ExpandableFeatureDescription/ExpandableButton"

type ExpandableItem = {
    label: string
    handler: () => void
    active: boolean
}

const ExpandableItem: React.FC<ExpandableItem> = ({
    label,
    handler,
    active,
    children,
}) => {
    return (
        <>
            <div
                className={`transition duration-200 ease-linear border-b last:border-b-0 ${
                    active ? "border-r-transparent" : "md:border-r"
                }`}
            >
                <div
                    className="font-bold md:pl-8 py-8 text-[1.375rem] flex group outline-none"
                    onClick={handler}
                    onKeyPress={handler}
                    role="button"
                    tabIndex={0}
                    style={{ WebkitTapHighlightColor: "transparent" }}
                >
                    <div className="flex justify-center items-center ">
                        <div className="hidden md:block">
                            <ExpandableButton active={active} />
                        </div>
                        <div className="md:hidden justify-self-center self-center">
                            {active ? (
                                <div
                                    className="border-b-[15px] border-l-[15px] border-l-[transparent]
                                border-r-[transparent] border-r-[15px] border-links "
                                />
                            ) : (
                                <div
                                    className="border-t-[15px] border-l-[15px] border-l-[transparent]
                                border-r-[transparent] border-r-[15px] border-links "
                                />
                            )}
                        </div>
                    </div>
                    <div
                        className={`pl-4 md:pl-8 md:pr-16 transition-colors duration-200 ease-linear ${
                            active ? "text-white" : "text-horizen-grey"
                        }`}
                    >
                        {label}
                    </div>
                </div>
                <div
                    className={` transition-opacity duration-500 ease-in-out md:absolute top-0 left-1/2 md:pl-12 pb-8 md:pb-0 md:pt-9 ${
                        active ? "" : "opacity-0 hidden md:block"
                    }`}
                >
                    <div className="text-horizen-content-grey text-base md:text-lg">
                        {children}
                    </div>
                </div>
            </div>
        </>
    )
}

export default ExpandableItem
