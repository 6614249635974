import React from "react"
import * as styles from "./IconWrapper.module.css"

export type IconWrapper = {
    label: string
    icon: React.ReactElement
    href: string
    openNewTab?: boolean
}

const IconWrapper: React.FC<IconWrapper> = ({
    label,
    icon,
    href,
    openNewTab,
}) => {
    return (
        <div className="group">
            <div className="border-t invisible border-links group-hover:visible" />

            <a
                className={`${styles.iconWrapper} h-full md:h-auto border flex flex-col items-center hover:border-links
                relative hover:before:absolute hover:before:w-0 hover:before:h-0 hover:before:border-t-[10px] hover:before:border-l-[10px] hover:before:border-l-[transparent] hover:before:border-r-[transparent] hover:before:border-r-[10px] hover:before:border-links hover:before:left-[calc(50%-10px)] hover:before:top-[0] hover:before:drop-shadow-triangle`}
                href={href}
                target={openNewTab ? "_blank" : ""}
                rel="noopener noreferrer"
            >
                <div className="md:mt-11">{icon}</div>
                <span className="font-bold text-lg md:text-2xl mb-5 md:my-10 text-center text-white">
                    {label}
                </span>
            </a>
        </div>
    )
}

export default IconWrapper
