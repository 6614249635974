import React from "react"

const MoreIcon = () => {
    return (
        <svg
            width="25"
            height="25"
            viewBox="0 0 25 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <circle
                cx="12.5"
                cy="12.5"
                r="11"
                fill="url(#paint0_linear_483_10)"
                stroke="#0C0C1C"
                strokeWidth="3"
            />
            <rect x="11" y="7" width="3" height="11" fill="white" />
            <rect
                x="18"
                y="11"
                width="3"
                height="11"
                transform="rotate(90 18 11)"
                fill="white"
            />
            <defs>
                <linearGradient
                    id="paint0_linear_483_10"
                    x1="12.5"
                    y1="0"
                    x2="12.5"
                    y2="25"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#26DB8D" />
                    <stop offset="1" stopColor="#0E9DE5" />
                </linearGradient>
            </defs>
        </svg>
    )
}

export default MoreIcon
