import React from "react"
import { useIntl } from "gatsby-plugin-intl"
import Button from "./Button"
import ContentContainer from "../templates/ContentContainer"
import Infographic from "./ZendooConstruction/Infographic"
import InfographicMobile from "./ZendooConstruction/InfographicMobile"
import SectionHeading from "./SectionHeading"

const ZendooConstruction = () => {
    const { formatMessage } = useIntl()

    return (
        <div className="text-white">
            <ContentContainer>
                <div className="lg:grid lg:grid-cols-3 space-y-8 lg:space-y-0">
                    <div>
                        <SectionHeading>
                            {formatMessage({
                                id: "zendoopage.construction.sectionHeading-1",
                            })}{" "}
                            <br />{" "}
                            {formatMessage({
                                id: "zendoopage.construction.sectionHeading-2",
                            })}
                        </SectionHeading>
                    </div>
                    <div className="col-span-2 space-y-10">
                        <p className="text-base md:text-lg lg:pr-16 mb-8 lg:mb-0">
                            {formatMessage({
                                id: "zendoopage.construction.p1",
                            })}
                        </p>
                        <div className="hidden lg:block">
                            <Button
                                label={formatMessage({
                                    id: "zendoopage.construction.cta",
                                })}
                                href={formatMessage({
                                    id: "zendoopage.construction.cta.link",
                                })}
                            />
                        </div>
                    </div>
                </div>
            </ContentContainer>
            <ContentContainer>
                <div className="hidden lg:flex justify-between mt-10">
                    <div className="border border-horizen-content-grey w-10 h-10 border-r-0 border-b-0" />
                    <div className="border border-horizen-content-grey w-10 h-10 border-l-0 border-b-0" />
                </div>
                <div className="hidden lg:block">
                    <Infographic />
                </div>
            </ContentContainer>
            <div className="lg:hidden">
                <InfographicMobile />
            </div>
            <ContentContainer>
                <div className="hidden lg:flex justify-between">
                    <div className="border border-horizen-content-grey w-10 h-10 border-r-0 border-t-0" />
                    <div className="border border-horizen-content-grey w-10 h-10 border-l-0 border-t-0" />
                </div>
                <div className="lg:hidden flex justify-center mt-8">
                    <Button
                        label={formatMessage({
                            id: "zendoopage.construction.cta",
                        })}
                        href={formatMessage({
                            id: "zendoopage.construction.cta.link",
                        })}
                    />
                </div>
            </ContentContainer>
        </div>
    )
}

export default ZendooConstruction
