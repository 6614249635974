import React from "react"

const GithubIcon = () => {
    return (
        <svg
            width="118"
            height="118"
            viewBox="0 0 118 118"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M16.3672 31.2626V16.3685L31.2624 16.3685M31.2624 101.633H16.3674L16.3674 86.738M86.7377 16.3676L101.631 16.3674V31.2625M101.631 86.7378V101.632H86.7377"
                stroke="white"
                strokeWidth="0.5"
            />
            <g filter="url(#filter0_d_410_173)">
                <circle cx="58.9999" cy="59.0002" r="30.7143" fill="#0E9DE5" />
            </g>
            <g filter="url(#filter1_d_410_173)">
                <circle cx="59" cy="59" r="25" fill="#0C0C1C" />
            </g>
            <g clipPath="url(#clip0_410_173)">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M59.1988 41C55.1146 41.0004 51.1639 42.4541 48.0535 45.101C44.9431 47.7478 42.8759 51.415 42.222 55.4465C41.568 59.4779 42.3699 63.6105 44.4841 67.1049C46.5984 70.5992 49.887 73.2271 53.7616 74.5186C54.6211 74.6777 54.9367 74.1454 54.9367 73.6912C54.9367 73.2812 54.9209 71.9262 54.9134 70.4891C50.1287 71.5291 49.1191 68.4598 49.1191 68.4598C48.803 67.42 48.1256 66.5275 47.2091 65.9434C45.6491 64.8759 47.3268 64.8978 47.3268 64.8978C47.8722 64.973 48.3932 65.1718 48.8501 65.479C49.307 65.7862 49.6877 66.1937 49.9632 66.6703C50.196 67.0947 50.5107 67.4687 50.889 67.7707C51.2673 68.0727 51.7018 68.2966 52.1672 68.4296C52.6327 68.5626 53.1199 68.602 53.6006 68.5455C54.0814 68.4889 54.5462 68.3377 54.9681 68.1003C55.0453 67.2278 55.4328 66.4118 56.0601 65.8004C52.2401 65.3657 48.224 63.8904 48.224 57.3004C48.2 55.5911 48.8346 53.9381 49.9963 52.684C49.4702 51.2009 49.5298 49.573 50.1629 48.1323C50.1629 48.1323 51.6072 47.6703 54.8936 49.8955C57.7128 49.1235 60.6877 49.1235 63.507 49.8955C66.7898 47.6703 68.2319 48.1323 68.2319 48.1323C68.8663 49.5725 68.9267 51.2007 68.4007 52.684C69.5627 53.9377 70.1966 55.5911 70.1707 57.3004C70.1707 63.9063 66.1473 65.3603 62.3175 65.7863C62.7272 66.2037 63.0432 66.7036 63.2444 67.2529C63.4455 67.8021 63.5272 68.388 63.4839 68.9713C63.4839 71.2725 63.4639 73.1245 63.4639 73.6912C63.4639 74.149 63.7739 74.6852 64.6451 74.5164C68.5181 73.2228 71.8046 70.5936 73.9168 67.0991C76.029 63.6045 76.8292 59.4724 76.1742 55.442C75.5191 51.4115 73.4517 47.7455 70.3416 45.0997C67.2314 42.4538 63.2815 41.0006 59.1982 41H59.1988Z"
                    fill="white"
                />
                <path
                    d="M48.5143 65.6936C48.4765 65.7793 48.3419 65.8049 48.2195 65.7462C48.0947 65.69 48.0248 65.5735 48.0652 65.4878C48.1021 65.3999 48.2368 65.3754 48.3614 65.4341C48.4863 65.4903 48.5575 65.608 48.5143 65.6941V65.6936Z"
                    fill="white"
                />
                <path
                    d="M49.2111 66.4707C49.129 66.5467 48.9685 66.5113 48.8598 66.3907C48.8074 66.3487 48.7731 66.2884 48.7638 66.2219C48.7545 66.1555 48.7709 66.0879 48.8098 66.0333C48.8944 65.9573 49.0498 65.9933 49.1625 66.1133C49.2752 66.2333 49.2971 66.3933 49.2115 66.4707H49.2111Z"
                    fill="white"
                />
                <path
                    d="M49.8889 67.4611C49.7834 67.5346 49.611 67.4658 49.5045 67.3128C49.398 67.1598 49.399 66.9764 49.507 66.9029C49.615 66.8294 49.7834 66.8957 49.8914 67.0473C49.9965 67.2028 49.9965 67.3863 49.8889 67.4611Z"
                    fill="white"
                />
                <path
                    d="M50.818 68.418C50.7237 68.522 50.5229 68.4939 50.376 68.3521C50.3072 68.3047 50.2589 68.2331 50.2408 68.1515C50.2227 68.07 50.236 67.9847 50.2782 67.9126C50.3738 67.8086 50.5757 67.8378 50.7237 67.9785C50.8731 68.117 50.9184 68.3151 50.818 68.4185V68.418Z"
                    fill="white"
                />
                <path
                    d="M52.0996 68.9739C52.0579 69.1085 51.8646 69.1697 51.6696 69.1124C51.4749 69.0534 51.3478 68.8957 51.387 68.7597C51.4262 68.6237 51.6218 68.5603 51.8179 68.6215C52.0123 68.6802 52.1397 68.8368 52.0993 68.9739H52.0996Z"
                    fill="white"
                />
                <path
                    d="M53.5074 69.0766C53.5121 69.2184 53.3474 69.3366 53.1424 69.3386C52.9369 69.3433 52.7703 69.2286 52.7681 69.0886C52.7681 68.9454 52.9297 68.8286 53.1352 68.8255C53.3407 68.8224 53.5074 68.9355 53.5074 69.0764V69.0766Z"
                    fill="white"
                />
                <path
                    d="M54.817 68.8539C54.8414 68.9921 54.6992 69.1339 54.4962 69.1721C54.2962 69.2088 54.1118 69.1232 54.0862 68.986C54.0615 68.8442 54.2062 68.702 54.4055 68.6653C54.6088 68.63 54.7911 68.7132 54.817 68.8539Z"
                    fill="white"
                />
            </g>
            <g filter="url(#filter2_d_410_168)">
                <circle
                    className="group-hover:animate-spin"
                    style={{
                        transformOrigin: "center",
                        animationDuration: "10000ms",
                    }}
                    cx="59"
                    cy="59"
                    r="42.5"
                    stroke="white"
                    strokeDasharray="5 5"
                    shapeRendering="crispEdges"
                />
            </g>
            <defs>
                <filter
                    id="filter0_d_410_173"
                    x="12.2856"
                    y="12.2859"
                    width="93.4287"
                    height="93.4285"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset />
                    <feGaussianBlur stdDeviation="8" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0.626318 0 0 0 0 0.941667 0 0 0 1 0"
                    />
                    <feBlend
                        mode="normal"
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow_410_173"
                    />
                    <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="effect1_dropShadow_410_173"
                        result="shape"
                    />
                </filter>
                <filter
                    id="filter1_d_410_173"
                    x="18"
                    y="18"
                    width="82"
                    height="82"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset />
                    <feGaussianBlur stdDeviation="8" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0.626318 0 0 0 0 0.941667 0 0 0 1 0"
                    />
                    <feBlend
                        mode="normal"
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow_410_173"
                    />
                    <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="effect1_dropShadow_410_173"
                        result="shape"
                    />
                </filter>
                <filter
                    id="filter2_d_410_173"
                    x="0"
                    y="0"
                    width="118"
                    height="118"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset />
                    <feGaussianBlur stdDeviation="8" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0.626318 0 0 0 0 0.941667 0 0 0 1 0"
                    />
                    <feBlend
                        mode="normal"
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow_410_173"
                    />
                    <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="effect1_dropShadow_410_173"
                        result="shape"
                    />
                </filter>
                <clipPath id="clip0_410_173">
                    <rect
                        width="34.3975"
                        height="33.5482"
                        fill="white"
                        transform="translate(42 41)"
                    />
                </clipPath>
            </defs>
        </svg>
    )
}

export default GithubIcon
