import React, { useEffect, useRef, useState } from "react"
import { ScrollTrigger } from "gsap/ScrollTrigger"
import { gsap } from "gsap"
import { useIntl } from "gatsby-plugin-intl"
import * as styles from "./Infographic.module.css"

import MinusIcon from "../MinusIcon"
import MoreIcon from "../MoreIcon"
import ZendooInfographic from "../../assets/ZendooInfographic.png"

const Infographic = () => {
    const { formatMessage } = useIntl()

    const [isBlock1Expanded, setIsBlock1Expanded] = useState(false)
    const [isBlock2Expanded, setIsBlock2Expanded] = useState(false)

    gsap.registerPlugin(ScrollTrigger)

    const infographicWrapperRef = useRef<HTMLDivElement>(null)

    useEffect(() => {
        const element = infographicWrapperRef.current
        const q = gsap.utils.selector(element)

        gsap.fromTo(
            q(".block1"),
            { opacity: 0 },
            {
                opacity: 1,
                scrollTrigger: {
                    trigger: q(".block1"),
                    start: "top-=100 center",
                    end: "bottom center",
                },
            }
        )

        gsap.fromTo(
            q(".block2"),
            { opacity: 0 },
            {
                opacity: 1,
                scrollTrigger: {
                    trigger: q(".block2"),
                    start: "top-=200 center",
                    end: "bottom center",
                },
            }
        )
    }, [])

    return (
        <div ref={infographicWrapperRef}>
            <div className="relative">
                <img src={ZendooInfographic} width="100%" alt="" />
                <div
                    className={`${styles.moreIcon} cursor-pointer block absolute left-[52.75%] top-[23.5%]`}
                >
                    {!isBlock1Expanded && <MoreIcon />}
                </div>

                <div
                    onClick={() => setIsBlock1Expanded(true)}
                    onKeyPress={() => setIsBlock1Expanded(true)}
                    className="outline-none text-transparent absolute cursor-pointer block z-[5] overflow-hidden w-[9.5%] h-[8.7%] left-[44.5%] top-[23.5%]"
                    role="button"
                    tabIndex={0}
                >
                    Click for expand
                </div>

                <div
                    className={`outline-none absolute top-[24%] left-[19.5%] w-[30vw] mr-5 p-8 transition-opacity duration-200 ease-linear border bg-[rgba(12,12,28,0.9)] ${
                        isBlock1Expanded ? "opacity-100 z-[5]" : "opacity-0 z-0"
                    }`}
                    onClick={() => setIsBlock1Expanded(false)}
                    onKeyPress={() => setIsBlock1Expanded(false)}
                    role="button"
                    tabIndex={0}
                >
                    <div className="absolute top-[-0.75rem] right-[-0.75rem]">
                        <MinusIcon />
                    </div>
                    <div className="relative">
                        <span className="block text-2xl font-bold mb-4">
                            {formatMessage({
                                id: "zendoopage.infographic.cctp",
                            })}
                        </span>
                        <p className="mb-4">
                            {formatMessage({
                                id: "zendoopage.infographic.cctp.content",
                            })}
                        </p>
                        <a href="https://academy.horizen.io/horizen/expert/sidechains/">
                            [{" "}
                            {formatMessage({
                                id: "zendoopage.infographic.cctp.learnMore",
                            })}{" "}
                            ]
                        </a>
                    </div>
                </div>

                <div className="block1 absolute top-[27%] left-[62.5%] w-[350px]">
                    <span className="block text-xl font-bold mb-4">
                        {formatMessage({
                            id: "zendoopage.infographic.block1.title",
                        })}
                    </span>
                    <ul className="text-horizen-content-grey list-disc pl-8">
                        <li>
                            {formatMessage({
                                id: "zendoopage.infographic.block1.list.1",
                            })}
                        </li>
                        <li>
                            {formatMessage({
                                id: "zendoopage.infographic.block1.list.2",
                            })}
                        </li>
                        <li>
                            {formatMessage({
                                id: "zendoopage.infographic.block1.list.3",
                            })}
                        </li>
                        <li>
                            {formatMessage({
                                id: "zendoopage.infographic.block1.list.4",
                            })}
                        </li>
                        <li>
                            {formatMessage({
                                id: "zendoopage.infographic.block1.list.5",
                            })}
                        </li>
                        <li>
                            {formatMessage({
                                id: "zendoopage.infographic.block1.list.6",
                            })}
                        </li>
                    </ul>
                </div>

                <div
                    className={`${styles.moreIcon} block cursor-pointer absolute left-[55.75%] top-[53.2%]`}
                >
                    {!isBlock2Expanded && <MoreIcon />}
                </div>
                <div
                    onClick={() => setIsBlock2Expanded(true)}
                    onKeyPress={() => setIsBlock2Expanded(true)}
                    className="outline-none text-transparent w-[8.7%] h-[5.9%] left-[48.4%] top-[53.2%] absolute cursor-pointer block z-[5] overflow-hidden"
                    role="button"
                    tabIndex={0}
                >
                    Click for expand
                </div>

                <div
                    className={`outline-none absolute top-[54%] left-[22.5%] w-[30vw] mr-5 p-8 transition-opacity duration-200 ease-linear border bg-[rgba(12,12,28,0.9)] ${
                        isBlock2Expanded ? "opacity-100 z-[5]" : "opacity-0 z-0"
                    }`}
                    onClick={() => setIsBlock2Expanded(false)}
                    onKeyPress={() => setIsBlock2Expanded(false)}
                    role="button"
                    tabIndex={0}
                >
                    <div className="absolute top-[-0.75rem] right-[-0.75rem]">
                        <MinusIcon />
                    </div>
                    <div className="relative">
                        <span className="block text-2xl font-bold mb-4">
                            {formatMessage({
                                id: "zendoopage.infographic.zen",
                            })}
                        </span>
                        <p className="mb-4">
                            {formatMessage({
                                id: "zendoopage.infographic.zen.content",
                            })}
                        </p>
                        <a href="https://www.horizen.io/zen/">
                            [{" "}
                            {formatMessage({
                                id: "zendoopage.infographic.zen.learnMore",
                            })}{" "}
                            ]
                        </a>
                    </div>
                </div>

                <div className="block2 absolute top-[65%] left-[62.5%] w-[350px]">
                    <span className="block text-xl font-bold mb-4">
                        {formatMessage({
                            id: "zendoopage.infographic.block2.title",
                        })}
                    </span>
                    <ul className="text-horizen-content-grey list-disc pl-8">
                        <li>
                            {formatMessage({
                                id: "zendoopage.infographic.block2.list.1",
                            })}
                        </li>
                        <li>
                            {formatMessage({
                                id: "zendoopage.infographic.block2.list.2",
                            })}
                        </li>
                        <li>
                            {formatMessage({
                                id: "zendoopage.infographic.block2.list.3",
                            })}
                        </li>
                        <li>
                            {formatMessage({
                                id: "zendoopage.infographic.block2.list.4",
                            })}
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    )
}

export default Infographic
