import React from "react"
import { useIntl } from "gatsby-plugin-intl"
import ContentContainer from "../templates/ContentContainer"
import HeroHeading from "./HeroHeading"
import SectionHeading from "./SectionHeading"

const ZendooHero = () => {
    const { formatMessage } = useIntl()

    return (
        <div className="pt-[9rem] md:pt-[calc(4rem+150px)]">
            <ContentContainer>
                <div>
                    <div className="text-center">
                        <HeroHeading>
                            {formatMessage({
                                id: "zendoopage.hero.heading-1",
                            })}{" "}
                            <br />
                            {formatMessage({
                                id: "zendoopage.hero.heading-2",
                            })}
                        </HeroHeading>
                        <p className="text-base md:text-lg text-horizen-content-grey max-w-3xl mx-auto my-5">
                            {formatMessage({
                                id: "zendoopage.hero.p1",
                            })}
                        </p>
                    </div>
                    <div className="flex justify-center">
                        <div className="border-b w-12 mt-10" />
                    </div>
                </div>
                <div className="text-white mt-14 lg:mt-28">
                    <div
                        className="space-y-4 md:space-y-0 md:grid md:grid-cols-3 relative md:before:absolute md:before:w-[14px] md:before:h-full md:before:border md:before:border-r-0
                    md:after:absolute md:after:w-[14px] md:after:h-full md:after:border md:after:border-l-0 md:after:right-0 h-[190px]"
                    >
                        <div className="">
                            <div className="md:pl-12 lg:pl-24 flex items-center h-full">
                                <SectionHeading>
                                    {formatMessage({
                                        id: "zendoopage.hero.box.title",
                                    })}
                                </SectionHeading>
                            </div>
                        </div>
                        <div className="col-span-2 flex flex-col justify-center">
                            <span className="font-bold text-[22px] md:text-2xl">
                                {formatMessage({
                                    id: "zendoopage.hero.box.subtitle",
                                })}
                            </span>
                            <p className="text-horizen-content-grey text-base md:text-lg mt-2 max-w-2xl">
                                {formatMessage({
                                    id: "zendoopage.hero.box.p1",
                                })}
                            </p>
                        </div>
                    </div>
                </div>
            </ContentContainer>
        </div>
    )
}

export default ZendooHero
