import React from "react"

const beforeAfterCssRules =
    "relative after:absolute after:w-2 after:h-full after:border-2 after:right-0 after:border-l-0 before:border-links before:absolute before:w-2 before:h-full before:border-2 before:left-0 before:border-r-0 after:border-links"

const hoverRules =
    "group-hover:before:border-white group-hover:after:border-white group-hover:text-horizen-green"

const ExpandableButton: React.FC<{ active: boolean }> = ({ active }) => {
    return (
        <button
            className={`${beforeAfterCssRules} ${hoverRules} px-3 font-bold text-xl leading-10 w-9 self-center
            ${
                active
                    ? "text-white before:border-white after:border-white"
                    : "text-links"
            }
            `}
            // eslint-disable-next-line react/button-has-type
            type="button"
        >
            {active ? "-" : "+"}
        </button>
    )
}

export default ExpandableButton
