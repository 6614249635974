import React from "react"
import { useIntl } from "gatsby-plugin-intl"
import Button from "./Button"
import CardBodyRow from "./HorizenSDKs/CardBodyRow"
import CardHeader from "./HorizenSDKs/CardHeader"
import SectionHeading from "./SectionHeading"

const HorizenSDKs = () => {
    const { formatMessage } = useIntl()
    const cards = [
        {
            label: formatMessage({
                id: "zendoopage.sdks.card1.label",
            }),
            features: [
                formatMessage({
                    id: "zendoopage.sdks.card1.features.1",
                }),
                formatMessage({
                    id: "zendoopage.sdks.card1.features.2",
                }),
                formatMessage({
                    id: "zendoopage.sdks.card1.features.3",
                }),
                formatMessage({
                    id: "zendoopage.sdks.card1.features.4",
                }),
                formatMessage({
                    id: "zendoopage.sdks.card1.features.5",
                }),
                formatMessage({
                    id: "zendoopage.sdks.card1.features.6",
                }),
            ],
        },
        {
            header: {
                subheading: formatMessage({
                    id: "zendoopage.sdks.card2.subheading",
                }),
                title: formatMessage({
                    id: "zendoopage.sdks.card2.title",
                }),
                subtitle: formatMessage({
                    id: "zendoopage.sdks.card2.subtitle",
                }),
            },
            features: [
                formatMessage({
                    id: "zendoopage.sdks.card2.features.1",
                }),
                formatMessage({
                    id: "zendoopage.sdks.card2.features.2",
                }),
                formatMessage({
                    id: "zendoopage.sdks.card2.features.3",
                }),
                formatMessage({
                    id: "zendoopage.sdks.card2.features.4",
                }),
                formatMessage({
                    id: "zendoopage.sdks.card2.features.5",
                }),
                formatMessage({
                    id: "zendoopage.sdks.card2.features.6",
                }),
            ],
            button: {
                label: formatMessage({
                    id: "zendoopage.sdks.card2.cta",
                }),
                link: formatMessage({
                    id: "zendoopage.sdks.card2.cta.link",
                }),
            },
        },
        {
            header: {
                subheading: formatMessage({
                    id: "zendoopage.sdks.card3.subheading",
                }),
                title: formatMessage({
                    id: "zendoopage.sdks.card3.title",
                }),
                subtitle: formatMessage({
                    id: "zendoopage.sdks.card3.subtitle",
                }),
            },
            features: [
                formatMessage({
                    id: "zendoopage.sdks.card3.features.1",
                }),
                formatMessage({
                    id: "zendoopage.sdks.card3.features.2",
                }),
                formatMessage({
                    id: "zendoopage.sdks.card3.features.3",
                }),
                formatMessage({
                    id: "zendoopage.sdks.card3.features.4",
                }),
                formatMessage({
                    id: "zendoopage.sdks.card3.features.5",
                }),
                formatMessage({
                    id: "zendoopage.sdks.card3.features.6",
                }),
            ],
            button: {
                label: formatMessage({
                    id: "zendoopage.sdks.card3.cta",
                }),
                link: formatMessage({
                    id: "zendoopage.sdks.card3.cta.link",
                }),
                disabled: true,
            },
        },
    ]
    return (
        <div className="text-white">
            <div className="px-6 md:px-12 lg:px-8 xl:px-2 max-w-screen-xl mx-auto">
                <SectionHeading>
                    {formatMessage({
                        id: "zendoopage.sdks.sectionHeading",
                    })}
                </SectionHeading>
                <div className="md:grid md:grid-cols-3 mt-8">
                    <div className="font-bold text-[22px] md:text-2xl md:pr-4 lg:pr-36 mb-8 md:mb-0">
                        {formatMessage({
                            id: "zendoopage.sdks.p1",
                        })}
                    </div>
                    <div className="col-span-2 text-horizen-content-grey text-base md:text-xl space-y-5">
                        <p>
                            {formatMessage({
                                id: "zendoopage.sdks.p2",
                            })}
                        </p>
                        <p>
                            {formatMessage({
                                id: "zendoopage.sdks.p3",
                            })}
                        </p>
                        <p className="md:hidden">{cards[0].label}</p>
                    </div>
                </div>
            </div>
            <div className="px-1 md:px-12 lg:px-8 xl:px-2 max-w-screen-xl mx-auto">
                <div className="mt-10 md:mt-20 lg:mt-40">
                    <div className="grid grid-cols-[23%,38.5%,38.5%] md:grid-cols-3 gap-0 lg:gap-x-4">
                        {cards.map((card) => {
                            return (
                                <>
                                    <div
                                        className={`text-horizen-content-grey row-start-1 px-2 md:px-5 pt-8 ${
                                            card.header
                                                ? "border border-b-0"
                                                : ""
                                        }
                                        ${
                                            card.header?.title === "Latus"
                                                ? "border-[#7A7E8C]"
                                                : ""
                                        }
                                        `}
                                    >
                                        {card.label && (
                                            <div className="hidden md:block text-lg lg:max-w-xs">
                                                {card.label}
                                            </div>
                                        )}

                                        {card.header && (
                                            <CardHeader
                                                subheading={
                                                    card.header.subheading
                                                }
                                                title={card.header.title}
                                                subtitle={card.header.subtitle}
                                            />
                                        )}
                                    </div>
                                    <div
                                        className={`row-start-2 px-2 md:px-5 md:py-8 pb-8 ${
                                            card.header
                                                ? "border border-b-0 border-t-0"
                                                : ""
                                        } ${
                                            card.header?.title === "Latus"
                                                ? "border-[#7A7E8C]"
                                                : ""
                                        }`}
                                    >
                                        {card.features.map((feature) => (
                                            <CardBodyRow
                                                fontGreen={feature.includes(
                                                    "✓"
                                                )}
                                                headerRow={!card.header}
                                            >
                                                {feature}
                                            </CardBodyRow>
                                        ))}
                                    </div>
                                    <div
                                        className={`flex justify-center mb-6 row-start-3 px-2 md:px-5 pt-4 md:pt-8 pb-2 md:pb-16 ${
                                            card.header
                                                ? "border border-t-0"
                                                : ""
                                        } ${
                                            card.header?.title === "Latus"
                                                ? "border-[#7A7E8C]"
                                                : ""
                                        }`}
                                    >
                                        {card.button && (
                                            <Button
                                                label={card.button.label}
                                                href={card.button.link}
                                                openNewTab
                                                disabled={card.button.disabled}
                                                customClass="text-xs !leading-5 md:text-xl lg:!leading-10"
                                            />
                                        )}
                                    </div>
                                </>
                            )
                        })}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default HorizenSDKs
