import React from "react"

export type CardBodyRow = {
    fontGreen?: boolean
    headerRow?: boolean
}

const CardBodyRow: React.FC<CardBodyRow> = ({
    fontGreen,
    headerRow,
    children,
}) => {
    return (
        <div
            className={`border-[#7A7E8C] border-b last:border-b-0 md:last:border-b flex py-2 text-xs md:text-base min-h-[65px] lg:min-h-[50px] items-center ${
                fontGreen ? "text-horizen-green" : ""
            } ${headerRow ? "text-horizen-content-grey" : "justify-center"}`}
        >
            {children}
        </div>
    )
}

export default CardBodyRow
