import React, { useState } from "react"
import { useIntl } from "gatsby-plugin-intl"
import * as styles from "./Infographic.module.css"

import MinusIcon from "../MinusIcon"
import MoreIcon from "../MoreIcon"
import ZendooInfographic from "../../assets/ZendooInfographicMobile.png"

const InfographicMobile = () => {
    const { formatMessage } = useIntl()

    const [isBlock1Expanded, setIsBlock1Expanded] = useState(false)
    const [isBlock2Expanded, setIsBlock2Expanded] = useState(false)
    const [isBlock3Expanded, setIsBlock3Expanded] = useState(false)
    const [isBlock4Expanded, setIsBlock4Expanded] = useState(false)
    return (
        <div className="relative">
            <div
                onClick={() => setIsBlock1Expanded(true)}
                onKeyPress={() => setIsBlock1Expanded(true)}
                className="z-[5] text-transparent absolute cursor-pointer block overflow-hidden w-[50.8%] h-[20.5%] left-[48%] top-[19.8%] outline-none"
                role="button"
                tabIndex={0}
            >
                Click for expand
            </div>
            <div
                className={`${styles.moreIcon} cursor-pointer block absolute left-[82.5%] top-[25.5%] md:left-[84%] md:top-[26.75%]`}
            >
                <MoreIcon />
            </div>
            <div
                className={`outline-none absolute top-[27%] left-[5vw] w-[90vw] mr-5 p-8 transition-opacity duration-200 ease-linear border bg-[rgba(12,12,28,0.9)] ${
                    isBlock1Expanded ? "opacity-100 z-[5]" : "opacity-0 z-0"
                }`}
                onClick={() => setIsBlock1Expanded(false)}
                onKeyPress={() => setIsBlock1Expanded(false)}
                role="button"
                tabIndex={0}
            >
                <div className="relative">
                    <div className="absolute top-[-2.6875rem] right-8">
                        <MinusIcon />
                    </div>
                    <span className="block text-2xl font-bold mb-4">
                        {formatMessage({
                            id: "zendoopage.infographic.cctp",
                        })}
                    </span>
                    <p className="mb-4">
                        {formatMessage({
                            id: "zendoopage.infographic.cctp.content",
                        })}
                    </p>
                    <a href="https://academy.horizen.io/horizen/expert/sidechains/">
                        {formatMessage({
                            id: "zendoopage.infographic.cctp.learnMore",
                        })}
                    </a>
                </div>
            </div>

            <div
                onClick={() => setIsBlock2Expanded(true)}
                onKeyPress={() => setIsBlock2Expanded(true)}
                className="z-[5] text-transparent absolute cursor-pointer block overflow-hidden w-[37.4%] h-[17.9%] left-[61.3%] top-[48.8%] outline-none"
                role="button"
                tabIndex={0}
            >
                Click for expand
            </div>
            <div
                className={`${styles.moreIcon} cursor-pointer block absolute left-[91%] top-[56%]`}
            >
                <MoreIcon />
            </div>
            <div
                className={`outline-none absolute top-[27%] left-[5vw] w-[90vw] mr-5 p-8 transition-opacity duration-200 ease-linear border bg-[rgba(12,12,28,0.9)] ${
                    isBlock2Expanded ? "opacity-100 z-[5]" : "opacity-0 z-0"
                }`}
                onClick={() => setIsBlock2Expanded(false)}
                onKeyPress={() => setIsBlock2Expanded(false)}
                role="button"
                tabIndex={0}
            >
                <div className="relative">
                    <div className="absolute top-[-2.6875rem] right-8">
                        <MinusIcon />
                    </div>
                    <span className="block text-2xl font-bold mb-4">
                        {formatMessage({
                            id: "zendoopage.infographic.zen",
                        })}
                    </span>
                    <p className="mb-4">
                        {formatMessage({
                            id: "zendoopage.infographic.zen.content",
                        })}
                    </p>
                    <a href="https://www.horizen.io/zen/">
                        {formatMessage({
                            id: "zendoopage.infographic.zen.learnMore",
                        })}
                    </a>
                </div>
            </div>

            <div
                className={`${styles.moreIcon} cursor-pointer block absolute left-[66.5%] top-[16.5%] md:left-[68.5%] md:top-[16.75%]`}
                onClick={() => setIsBlock3Expanded(true)}
                onKeyPress={() => setIsBlock3Expanded(true)}
                role="button"
                tabIndex={0}
            >
                <MoreIcon />
            </div>
            <div
                className={`outline-none absolute top-[27%] left-[5vw] w-[90vw] mr-5 p-8 transition-opacity duration-200 ease-linear border bg-[rgba(12,12,28,0.9)] ${
                    isBlock3Expanded ? "opacity-100 z-[5]" : "opacity-0 z-0"
                }`}
                onClick={() => setIsBlock3Expanded(false)}
                onKeyPress={() => setIsBlock3Expanded(false)}
                role="button"
                tabIndex={0}
            >
                <div className="relative">
                    <div className="absolute top-[-2.6875rem] right-8">
                        <MinusIcon />
                    </div>
                    <span className="block text-2xl font-bold mb-4">
                        {formatMessage({
                            id: "zendoopage.infographic.block1.title",
                        })}
                    </span>
                    <ul className="text-horizen-content-grey list-disc pl-8">
                        <li>
                            {formatMessage({
                                id: "zendoopage.infographic.block1.list.1",
                            })}
                        </li>
                        <li>
                            {formatMessage({
                                id: "zendoopage.infographic.block1.list.2",
                            })}
                        </li>
                        <li>
                            {formatMessage({
                                id: "zendoopage.infographic.block1.list.3",
                            })}
                        </li>
                        <li>
                            {formatMessage({
                                id: "zendoopage.infographic.block1.list.4",
                            })}
                        </li>
                        <li>
                            {formatMessage({
                                id: "zendoopage.infographic.block1.list.5",
                            })}
                        </li>
                        <li>
                            {formatMessage({
                                id: "zendoopage.infographic.block1.list.6",
                            })}
                        </li>
                    </ul>
                </div>
            </div>

            <div
                className={`${styles.moreIcon} cursor-pointer block absolute left-[28.5%] top-[45.5%] md:left-[30.25%] md:top-[46.5%] z-[2]`}
                onClick={() => setIsBlock4Expanded(true)}
                onKeyPress={() => setIsBlock4Expanded(true)}
                role="button"
                tabIndex={0}
            >
                <MoreIcon />
            </div>
            <div
                className={`outline-none absolute top-[27%] left-[5vw] w-[90vw] mr-5 p-8 transition-opacity duration-200 ease-linear border bg-[rgba(12,12,28,0.9)] ${
                    isBlock4Expanded ? "opacity-100 z-[5]" : "opacity-0 z-0"
                }`}
                onClick={() => setIsBlock4Expanded(false)}
                onKeyPress={() => setIsBlock4Expanded(false)}
                role="button"
                tabIndex={0}
            >
                <div className="relative">
                    <div className="absolute top-[-2.6875rem] right-8">
                        <MinusIcon />
                    </div>
                    <span className="block text-2xl font-bold mb-4">
                        {formatMessage({
                            id: "zendoopage.infographic.block2.title",
                        })}
                    </span>
                    <ul className="text-horizen-content-grey list-disc pl-8">
                        <li>
                            {formatMessage({
                                id: "zendoopage.infographic.block2.list.1",
                            })}
                        </li>
                        <li>
                            {formatMessage({
                                id: "zendoopage.infographic.block2.list.2",
                            })}
                        </li>
                        <li>
                            {formatMessage({
                                id: "zendoopage.infographic.block2.list.3",
                            })}
                        </li>
                        <li>
                            {formatMessage({
                                id: "zendoopage.infographic.block2.list.4",
                            })}
                        </li>
                    </ul>
                </div>
            </div>

            <img src={ZendooInfographic} alt="" className="w-full" />
        </div>
    )
}

export default InfographicMobile
