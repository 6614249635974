import { Helmet } from "react-helmet"
import React from "react"
import * as styles from "../styles/homepage/index.module.css"

import ExpandableSection from "../components/ExpandableSection"
import HorizenSDKs from "../components/HorizenSDKs"
import Layout from "../templates/Layout"
import LearnZendoo from "../components/LearnZendoo"
import NewsletterForm from "../components/NewsletterForm"
import ZendooConstruction from "../components/ZendooConstruction"
import ZendooFooter from "../assets/ZendooFooter.png"
import ZendooHero from "../components/ZendooHero"

const Zendoo = () => {
    return (
        <Layout>
            <div>
                <Helmet>
                    <meta name="theme-color" content="#041742" />
                    <meta
                        property="og:title"
                        content="Zendoo - Cross-Chain Platform for Building Blockchains"
                    />
                    <meta
                        property="og:image"
                        content="https://horizen.io/assets/images/meta/zendoo.jpg"
                    />
                    <meta
                        name="description"
                        content="Zendoo is Horizen's cross-chain protocol for developers to custom-build highly scalable private or public blockchains and dapps."
                    />
                    <meta
                        property="og:description"
                        content="Zendoo is Horizen's cross-chain protocol for developers to custom-build highly scalable private or public blockchains and dapps."
                    />
                    <meta
                        property="og:url"
                        content="https://www.horizen.io/zendoo/"
                    />
                    <meta property="og:type" content="website" />
                    <meta property="og:site_name" content="Horizen" />
                    <meta
                        name="twitter:title"
                        content="Zendoo - Cross-Chain Platform for Building Blockchains"
                    />
                    <meta
                        name="twitter:description"
                        content="Zendoo is Horizen's cross-chain protocol for developers to custom-build highly scalable private or public blockchains and dapps."
                    />
                    <meta
                        name="twitter:image"
                        content="https://horizen.io/assets/images/meta/zendoo.jpg"
                    />
                    <meta name="twitter:card" content="summary_large_image" />
                    <meta name="twitter:site" content="@horizenglobal" />
                    <meta name="twitter:creator" content="@horizenglobal" />
                    <meta
                        name="keywords"
                        content="zendoo, horizen zendoo, zendoo sidechain, cross chain platform, building blockchains, build dapps, zk snarks, decentralized sidechain, decentralized blockchain, cross chain transfer protocol, zero knowledge"
                    />
                    <title>
                        Zendoo - Cross-Chain Platform for Building Blockchains
                    </title>
                </Helmet>
                <div
                    className={`bg-horizen-hp-bg md:bg-contain pb-16 lg:pb-32 ${styles.zendooHero}`}
                >
                    <ZendooHero />
                </div>
                <div className="bg-horizen-hp-bg">
                    <ExpandableSection />
                </div>
                <div className="pt-14 lg:pt-56 bg-horizen-hp-bg">
                    <ZendooConstruction />
                </div>
                <div className="pt-14 lg:pt-48 bg-horizen-hp-bg">
                    <HorizenSDKs />
                </div>
                <div className="pt-14 lg:pt-48 bg-horizen-hp-bg">
                    <LearnZendoo />
                </div>
                <div
                    className="bg-cover"
                    style={{
                        backgroundImage: `url(${ZendooFooter})`,
                    }}
                >
                    <div className="pt-24 lg:pt-40">
                        <NewsletterForm />
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default Zendoo
